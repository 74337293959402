var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "retur-konsumen-modal",
      "no-close-on-backdrop": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(_vm._s(_vm.$route.params.id ? 'Simpan' : 'Selanjutnya'))])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Konsumen"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.konsumens,
      "label": "text"
    },
    model: {
      value: _vm.form.konsumen,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "konsumen", $$v);
      },
      expression: "form.konsumen"
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Faktur Penjualan"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.penjualans,
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "no-options",
      fn: function fn() {
        return [_c('i', {
          staticClass: "d-block text-center"
        }, [_vm._v(" Coba Pilih Konsumen yang lain ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.penjualan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "penjualan", $$v);
      },
      expression: "form.penjualan"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal Retur"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Keterangan (optional)"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }