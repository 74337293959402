<template>
    <b-overlay :show="loading">
      <form-filter-export @onExport="exportRetur"></form-filter-export>
        <retur-konsumen-modal :item="currentItem" @submit="submit">
        </retur-konsumen-modal>
        <b-card
        >
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-button
                v-if="allowCreate()"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah Retur
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="returs"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(no)="{index}">
                  {{ ++index }}
                </template>
      
                <template #cell(sales)="{item}">
                    {{ item.sales ? item.sales.nama_lengkap : '-' }}
                </template>
                <template #cell(penjualan)="{item}">
                    {{ item.penjualan ? item.penjualan.no_kwitansi : '-' }}
                </template>
                <template #cell(konsumen)="{item}">
                    {{ item.konsumen ? item.konsumen.nama_toko : '-' }}
                </template>
                <template #cell(total)="{item}">
                    {{ `Rp ${formatRupiah(item.total)}` }}
                </template>
                <template #cell(selesai)="{item}">
                    <b-badge v-if="item.selesai == 1 && item.gudang_id != null" variant="success">
                        SELESAI
                    </b-badge>
                    <b-badge v-else-if="item.selesai == 1 && item.gudang_id == null" variant="primary">
                        GUDANG TUJUAN BELUM DIPILIH
                    </b-badge>
                    <b-badge v-else variant="danger">
                        BELUM SELESAI
                    </b-badge>
                </template>
      
                <template #cell(actions)="{item}">
                      <b-button
                        size="sm"
                        @click.prevent="$router.push(`/retur-konsumen/detail/${item.id}`)"
                        class="mr-1"
                        variant="outline-info"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        v-if="allowUpdate() && item.selesai == 0"
                        size="sm"
                        @click="edit(item)"
                        class="mr-1"
                        variant="outline-info"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        size="sm"
                        v-if="allowDelete()"
                        @click="remove(item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                </template>
      
                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
    </b-overlay>
  </template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ReturKonsumenModal from './Modal.vue'
  import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
  import {
    BTable,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BCard,
    BFormCheckbox,
  } from "bootstrap-vue";
  import service from '@/services'
  
  export default {
    components: {
      FormFilterExport,
      ToastificationContent,
      VBTooltip,
      BCard,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BAvatar,
      BBadge,
      ReturKonsumenModal,
      BOverlay,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox ,
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        currentItem: {
          id_penjualan: null,
          id_konsumen: null,
          id_sales: null,
          nama_konsumen: null,
          tanggal: null,
          id_gudang_m: null,
          keterangan: null,
          rincian: []
        },
        checkedReturs: [],
        allChecked: false,
        id:null,
        blok_id:[],
        gudang_id :[],
        palet_id:[],
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "penjualan", label: "Nota Penjualan", sortable: true },
          { key: "konsumen", label: "Konsumen", sortable: true },
          { key: "tanggal", label: "Tanggal", sortable: true },
          { key: "total", label: "Total Retur", sortable: true },
          { key: "selesai", label: "Status", sortable: true },
          { key: "actions", label: "Aksi" }
        ],
        returs: [],
        loading: false
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    async created() {
      await this.setField()
      // Set the initial number of items
      this.getReturData()
    },
    methods: {
      async exportRetur({tgl_awal, tgl_akhir, type_export}) {
        if(!tgl_awal || !tgl_akhir) {
          this.displayError({
            message: 'Harap isi tanggal awal dan akhir!'
          })
          return false
        }

        try {
          this.loading = true
          const params = {
            tgl_awal,
            tgl_akhir,
            type_export
          }

          if(this.isSales) {
            params.id_sales = this.user && this.user.karyawan ? this.user.karyawan.id : null
          }

          const config = {
            url: `/export-retur-konsumen`,
            method: 'get',
            params,
            headers: {
                Authorization: `${localStorage.tokenType} ${localStorage.token}`
            }
          }
          const response = await service.sendRequest(config)
          this.loading = false
          await window.open(response.data)
          setTimeout(async () => {
              // delete selected file
              const arrFile = response.data.split('/')
              const filename = arrFile[ arrFile.length - 1 ]

              await this.clearExportedFile(filename)
            }, 1000)
        }
        catch(e) {
          this.loading = false
          this.displayError(e)
          return false
        }
      },
      async clearExportedFile(filename) {
            try {
                const config = {
                    url: `/clear-export`,
                    method: 'get',
                    params: {
                        filename
                    },
                    headers: {
                        Authorization: `${localStorage.tokenType} ${localStorage.token}`
                    }
                }
                const response = await service.sendRequest(config)

                return Promise.resolve(response.data)
            } catch (e) {
                if(e.response && e.response.data) {
                    const blob = e.response.data
                    const text = await blob.text()
                    const errorJson = JSON.parse(text)

                    return Promise.reject(errorJson)
                }
                return Promise.reject(e)        
            }
        },
        setField() {
          if(!this.isSales) {
            this.fields = [
              { key: "penjualan", label: "Nota Penjualan", sortable: true },
              {
                key: "sales",
                label: "Sales",
              },
              { key: "konsumen", label: "Konsumen", sortable: true },
              { key: "tanggal", label: "Tanggal", sortable: true },
              { key: "total", label: "Total Retur", sortable: true },
              { key: "selesai", label: "Status", sortable: true },
              { key: "actions", label: "Aksi" }
            ]
          }
        },
        async getReturData() {
            const params = {order: 'desc'}
            if(this.isSales) {
                params.id_sales = this.user.karyawan.id
            }

            if(!this.isSales && this.myGudang) {
              params.gudang_id = this.myGudang.id
            }
            this.loading = true
            const returs = await this.$store.dispatch('retur-konsumen/getData', params)
            const completeRetur = returs.filter(item => item.selesai == 1)
            const otherRetur = returs.filter(item => item.selesai != 1)
            this.returs = [...completeRetur, ...otherRetur]
            if(!this.isSales) {
              this.returs = [...otherRetur, ...completeRetur]
            }
            this.loading = false
            this.totalRows = this.returs.length
            
        },
      add() {
        this.id = null
        this.currentItem = {
          id_penjualan: null,
          id_konsumen: null,
          id_sales: null,
          nama_konsumen: null,
          tanggal: this.getCurrentDate(),
          id_gudang_m: null,
          keterangan: null,
          rincian: []
        }
        this.$bvModal.show('retur-konsumen-modal')
      },
      edit(item) {
        this.id = item.id
        const currentItem = Object.assign({}, item)
        currentItem.nama_konsumen = currentItem.konsumen ? currentItem.konsumen.nama_toko : ''
        currentItem.id_penjualan = {
          value: currentItem.penjualan.id,
          text: `${currentItem.penjualan.no_kwitansi} - ${currentItem.konsumen ? currentItem.konsumen.nama_toko : ''}`,
          nama_konsumen: currentItem.konsumen ? currentItem.konsumen.nama_toko : '-',
          id_konsumen: currentItem.konsumen  ? currentItem.konsumen.id : null,
          rincian: []
        }
        currentItem.id_konsumen = currentItem.konsumen.id
        currentItem.id_sales = currentItem.sales.id
        currentItem.id_gudang_m = currentItem.sales.id
        this.currentItem = currentItem
        this.$bvModal.show('retur-konsumen-modal')
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data Retur ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-outline-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('retur-konsumen/save', [item])
            .then(() => {
              this.getReturData()
              this.displaySuccess({
                message: 'Berhasil'
              })
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        delete this.form.id
        this.form.palet_id = null
        this.form.blok_id = null
        this.form.gudang_id = null
        this.form.rak = null
      },
      async submit(payload) {
        if(this.id) {
            payload.id = this.id
        }

        try {
            this.loading = true
            // create retur
            const retur = await this.$store.dispatch('retur-konsumen/save', [payload])

            // create retur barang if rincian baran penjualan exist
            if(payload.rincian.length > 0) {
                const payloadsRincian = []
                payload.rincian.map(rincian => {
                    if(rincian.barang) {
                        payloadsRincian.push({
                            id_retur_konsumen: retur.id,
                            id_barang: rincian.barang.id,
                            qty: rincian.qty,
                            harga_jual: rincian.harga_jual
                        })
                    }
                })
                await this.$store.dispatch('retur-konsumen-rincian/save', payloadsRincian)
            }
            this.loading = false

            this.displaySuccess({
                message: 'Retur Konsumen berhasil ditambahkan'
            })

            // if edit action just reload data
            this.$bvModal.hide('retur-konsumen-modal')
            // if(this.id) {
            //     this.id = null
            await this.getReturData()
            // }
            // else {
                this.id = null
                setTimeout(() => {
                    this.$router.push(`/retur-konsumen/detail/${retur.id}`)
                }, 1000)
            // }
        }
        catch(e) {
            this.loading = false
            this.displayError(e)
            return false
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    },
  };
  </script>
  